<template>
    <div class="o365-filter-cell" tabindex="-1" :data-o365-colindex="colIndex" :style="{width: col.width+col.widthAdjustment+'px', left: col.left+'px'}" ref="elRef"
        :o365-field="col.colId">
        <ErrorRenderer v-if="capturedError" />
        <template v-else-if="col.filter || col.filterSlot">
            <!--<component :is="col.filter" :data-object-id="dataGridControl.dataObject.dataObjectId" :column="col">
            </component>-->
          <!--  <div class="o365-search" :class="{'text-center':['bit','boolean'].indexOf(col.type) >-1}">-->
                <component v-if="col.filterSlot" :is="col.filterSlot" :hideDropdown="col.disableFilterDropdown" :filterObject="filterObject" :columnName="col.filterField ?? col.field" :column="col" ref="componentRef" :autoSearchDebounce="dataGridControl.props.autoFilterDebounce"></component>
                <component v-else-if="col.colId === 'AutoTreeGroup'" :hideDropdown="col.disableFilterDropdown" :is="col.filter" hideColumn :filterObject = "filterObject" class="bg-light-subtle border-0" :columnName="col.filterField ?? col.field" hidePlaceholder pointerOnly ref="componentRef" :autoSearchDebounce="dataGridControl.props.autoFilterDebounce"></component>
                <component v-else :is="col.filter" hideColumn :hideDropdown="col.disableFilterDropdown"  :filterObject = "filterObject" class="bg-light-subtle border-0" :columnName="col.filterField ?? col.field" hidePlaceholder pointerOnly ref="componentRef" :autoSearchDebounce="dataGridControl.props.autoFilterDebounce">
                </component>
           <!-- </div>-->

        </template>
        <template v-else-if="col.colId === 'o365_System'">
            <div class="w-100 text-center">
                <i class="bi bi-search"></i>
            </div>
        </template>
        <template v-else-if="col.colId === 'o365_Action'">
            <div class="w-100 text-center">
                <button class="btn btn-link btn-sm px-0" 
                    v-if="showBulkDelete"
                    @click="bulkDeleteSelected"
                    :title="$t('Bulk delete selected rows')">
                    <i class="bi bi-x-lg"></i>
                </button>
            </div>
        </template>
        <template v-else-if="col.colId === 'o365_MultiSelect'">
            <div class="w-100 text-center">
                <button class="btn btn-link btn-sm" 
                    v-if="isRowSelected"
                    @click="copySelected"
                    :title="$t('Copy selected rows as JSON. To copy as plain text press and hold Ctrl.')">
                    <i :class="[copied ? 'bi-clipboard2-check' : 'bi-clipboard2', 'bi']"></i>
                </button>
            </div>
        </template>
    </div>
</template>

<script setup>
import { ref, computed, provide, nextTick } from 'vue';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';
const props = defineProps({
    col: Object,
    colIndex: Number,
    dataGridControl: Object
});

provide('is-in-grid-cell', true);

const dataObject = props.dataGridControl.dataObject;
const filterObject = props.dataGridControl.dataObject?.filterObject ?? props.dataGridControl.filterObject;
const isRowSelected = computed(() => {
    return dataObject?.data?.filter((item) => item?.isSelected).length > 0
});
const componentRef = ref(null);
const elRef = ref(null);
const copied = ref(false);

const showBulkDelete = computed(() => {
    return dataObject?.allowDelete && dataObject?.selectionControl.selectedRows?.length > 1;
});

function bulkDeleteSelected() {
    
    if (dataObject?.selectionControl.selectedRows.length < dataObject?.selectionControl.selectedUniqueKeys.size){
        dataObject?.recordSource.bulkDeleteByPrimKeys(Array.from(dataObject?.selectionControl.selectedUniqueKeys), { confirm: true });
    } else {
        dataObject?.recordSource.bulkDelete(dataObject?.selectionControl.selectedRows, { confirm: true });
    } 
}

function copySelected(e) {
    if (dataObject) {
        dataObject.selectionControl.copySelection(false, props.dataGridControl.dataColumns.columns, !e.ctrlKey)
    } else {
        props.dataGridControl.selectionControl.copySelection(false, props.dataGridControl.dataColumns.columns, !e.ctrlKey)
    }
    copied.value = true;
    window.setTimeout(() => {
        copied.value = false;
    }, 1000);
}

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render column header: ${props.col?.colId}`,
    errorRenderFunctionOptions: {
        uiTitleMessage: 'An error has occured when trying to render this cell'
    }
});

function focusInput() {
    if (componentRef.value?.componentResolved) {
        componentRef.value.componentResolved.then(async () => {
            await nextTick();
            window.requestAnimationFrame(() => {
                elRef.value?.querySelector('input')?.focus();
            });
        });
    }
}

defineExpose({ focusInput });

</script>

<script>
    import  OFilter  from 'o365.vue.components.FieldFilter.vue';
    export default {
        name: 'ODataGridHeaderFilterCell',
        components: { OFilter }
    }
</script>
